/* Внешний контейнер Header */
.wrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #353535;
  }
  
  /* Верхняя часть хэдера */
  .container {
    max-width: 1170px;
    /* min-width: 768px; */
    /* padding: 0px 140px; */
    display: flex;
    /* height: 110px; */
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    
  }
  .header-block{
    max-width: 1170px;
    /* min-width: 768px; */
    /* padding: 0px 140px; */
    display: flex;
    /* height: 110px; */
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
  }
  
  /* Верхняя часть Хэдера. Контейнер с кнопками поиска, понравившееся и корзина */
  .container-buttons {
    display: flex;
    max-width: 500px;
    /* width: 100%; */
    
  }
  .n{
    flex-basis: 80px;
  }
  
  @media (max-width: 1200px) {
    .container{
      margin: 0 20px;
    }
  }

  @media (max-width: 768px) {
    .form-search {
        display: none;
    }

    .container {
        min-width: 0px;
        padding-top: 20px;
    }
  }
  
  .button {
    width: 40px;
    height: 44px;
    background-color: #353535;
    padding: 0px;
    border: 0px;
    gap: 20px;
  }
  .button :hover{
    cursor: pointer;
  }
  .form-search {
    display: flex;
    /* display: none; */
  }
  
  .input-search {
    width: 230px;
    height: 44px;
    border: 1px solid #B3B3B3;
    padding: 0px;
  
    font-family: 'Arsenal';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 0.03em;
    color: #353535;
    box-sizing:border-box;
    padding-left: 15px;
    outline: none;
  }
  input::placeholder { color : #B3B3B3}
  
  .burger-nav {
    display: flex;
    width: 70px;
    height: 44px;
    background-color: #353535;
    padding: 0px;
    border: 0px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .burger-btn {
    width: 25px;
    height: 16px;
    position: relative;
 
  }
  .burger-btn:before {
    content: '';
    position: absolute;
    top: 0px;
    width: 25px;
    background-color: white;
    height: 2px;
  }

.burger-btn span {
    content: '';
    position: absolute;
    top: 7px;
    width: 25px;
    background-color: white;
    height: 2px;
}

  .burger-btn:after {
    content: '';
    position: absolute;
    bottom: 0px;
    width: 25px;
    background-color: white;
    height: 2px;
  }

  /* Контейнер нижней части Header */
  .navigation-bar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 250px;
    height: 80px;
    width: 100%;
  }
  @media (max-width: 768px) {
    .navigation-bar {
        display: none;
    }
  }
  
  /* Класс ссылки */
  .navigation-links {
    font-family: 'Arsenal';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 0.03em;
    color: #ffffff;
    padding: 0px 0px;
    /* padding-top: 5px; */
    
    /* height: 30px; */
    text-decoration: none;
  }
  .navigation-links:hover {
    color: #949494;;
  }
  
  .navigation-links.events {
    /* padding-right: 30px; */
    border-right: unset;
  }
  
  #logoMain {
    height: 50px;
    width: 50px;
    
  }

  @media (max-width: 768px) {
    .button.hide {
        display: none;
    }
    .form-search.hide{
        display: none;
    }
    .container-buttons{
        justify-content: flex-end;
        gap: 5px;
        padding-left: 10px;
    }
    .container {
        height: 100px;
        /* border-bottom: 1px solid #B3B3B3; */
    }
  }
  
  @media (min-width: 769px) {
    .burger-nav {
        display: none;
    }
    .menu {
        display: none;
    }
  }
  