.wrapper-category{
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 870px;
}

.container-category{
    display: flex;
    max-width: 870px;
    height: 430px;
    gap: 30px;
}

.category-card {
    width: 270px;
    min-height: 200px;
    display: flex;
    position: relative;
    justify-content: center;
}

.category-card img{
    min-width: 0;
    max-width: 100%;
    object-fit: cover;
}

.category-card-container{
    display: flex;
    flex-direction: row;
    gap: 30px;
    flex-wrap: wrap;
    width: 570px;
}

.wrapper-category button{
    align-self: center;
}

.category-btn{
    position: absolute;
    width: 210px;
    height: 57px;
    background: white;
    bottom: 30px;
    padding: 0px;
    border: 0px;
    
    font-family: 'Arsenal';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #353535;

}

@media (max-width:1189px) {
    .wrapper-category{
        width: 715px;
        gap: 20px;
    }

    .container-category{
        max-width: 715px;
        gap: 20px;
        height: 420px;
    }

    .category-card-container{
        width: 470px;
        gap: 20px;
    }

    .category-card{
        max-width: 225px;
    }

    .category-btn{
        width: 165px;
        height: 45px;
        font-size: 17px;
    }
}

@media (max-width:999px) {
    .wrapper-category{
        width: 480px;
    }

    .container-category{
        max-width: 480px;
        height: 416px;
    }

    .category-card-container{
        width: 230px;
        gap: 16px;
    }
    .category-card-container div:nth-child(n+3){
        display: none;
    }

    .category-card{
        max-width: 230px;
    }
}

@media (max-width:767px) {
    .wrapper-category{
        width: 100%;
    }

    .category-card-container{
        width: unset;
        gap: 20px;
        flex-wrap: nowrap;
    }

    .category-card{
        max-width: unset;
        width: unset;
    }

    .container-category{
        max-width: 100%;
        height: 200px;
        align-self: center;
    }

    #blokHide{
        display: none;
    }
}

@media (max-width:479px) {

    .category-card{
        min-height: unset;
    }

    .category-btn{
        width: 110px;
        height: 36px;
        font-size: 10px;
        bottom: 10px;
    }
}