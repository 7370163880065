.modalWrapper {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
}


.modalContainer {
    /* width: 380px; */
    padding: 36px;
    background: #353535;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}

.modalTitle {
    font-family: 'Arsenal';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;

    text-align: center;
    color: white
}

.modalContentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;

    width: 100%;
}

.modalSubtitle {
    font-family: 'Arsenal';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    color: white
}

.modalText {
    font-family: 'Arsenal';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    color: white
}

.modalSubmitButton {
    font-family: 'Arsenal';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
    color: #353535;
    cursor: pointer;

    background: #FFFFFF;
    padding: 20px;
}