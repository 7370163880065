.store-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 1170px;
    margin: 0px auto;
    position: relative;
}

.store-title-line {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.store-title {
    font-family: 'Moniqa';
    font-style: normal;
    font-weight: 400;
    font-size: 80px;
    line-height: 83px;
    letter-spacing: 0.08em;
    color: #353535;
    text-transform: uppercase;
    align-self: flex-start;
    margin: 10px 0px;
}

.store-filters-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.store-filter-img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.store-filter-title {
    font-family: 'Arsenal';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    text-align: right;
    letter-spacing: 0.03em;
    color: #353535;
}

.store-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.store-card {
    position: relative;
    height: calc(538px - 45px);
    width: calc(27% - 30px);
    min-width: calc(370px - 30px);
    padding: 15px 15px 30px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #B3B3B3;
    background-color: white;
    margin-bottom: 30px;
    cursor: pointer;
}

.store-card-img-container{
    width: fit-content;
    height: fit-content;
    position: relative;
    cursor: pointer;
}

.store-card-img {
    width: 100%;
    height: 335px;
    object-fit: contain;
}

.store-invest-rating {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 10px;
    right: 10px;
    left: auto;
}

.store-card-title {
    font-family: 'Arsenal';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.03em;
    color: #353535;
}

.store-card-subtitle-line {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.store-card-subtitle-left {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.store-card-subtitle {
    font-family: 'Arsenal';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.03em;
    color: #B3B3B3;
    margin-bottom: 10px;
}

.store-card-author {
    font-family: 'Arsenal';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 0.03em;
    color: #353535;
}

.store-card-btn {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.store-card-price-line {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.store-card-price {
    font-family: 'Arsenal';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.03em;
    color: #353535;
}

.store-card-btn-line {
    display: none;
}

.store-card-info-mobile-container{
    display: none;
}

@media screen and (max-width: 1189px) and (min-width: 1000px) {
    .store-wrapper {
        /* max-width: 960px; */
        width: calc(100% - 40px);
    }

    .store-title {
        font-size: 70px;
        line-height: 73px;
    }

    .store-card {
        min-width: calc(300px - 30px);
        height: calc(469px - 45px);
    }

    .store-card-img {
        height: auto;
    }

    .store-card-title {
        font-size: 20px;
        line-height: 25px;
    }
}

@media screen and (max-width: 999px) and (min-width: 768px) {
    .store-wrapper {
        /* max-width: 728px; */
        width: calc(100% - 40px);
    }

    .store-title {
        font-size: 70px;
        line-height: 73px;
    }

    .store-card {
        min-width: calc(229px - 30px);
        height: calc(412px - 45px);
        margin-bottom: 30px;
    }

    .store-card-img {
        height: auto;
    }

    .store-card-subtitle-line .store-card-btn,
    .store-card-price-line .store-card-btn {
        display: none;
    }

    .store-card-subtitle-line {
        justify-content: center;
    }

    .store-card-subtitle-left {
        align-items: center;
    }

    .store-card-price-line {
        justify-content: center;
    }

    .store-card-btn-line {
        display: flex;
        width: 80%;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        margin-top: 5px;
    }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
    .store-wrapper {
        /* max-width: 440px; */
        width: calc(100% - 40px);
    }

    .store-card{
        width: calc(100% - 30px);
        flex-direction: row;
        height: fit-content;
        padding-bottom: 15px;
    }

    .store-card-img-container{
        width: 49%;
        height: fit-content;
    }

    .store-card-img{
        width: 100%;
        height: auto;
    }

    .store-card > .store-card-title, .store-card > .store-card-subtitle-line, .store-card > .store-card-price-line{
        display: none;
    }

    .store-card-price-line{
        justify-content: center;
    }

    .store-card-price-line .store-card-btn {
        display: none;
    }
    
    .store-card-subtitle-line{
        justify-content: center;
    }

    .store-card-subtitle-line .store-card-btn {
        display: none;
    }

    .store-card-info-mobile-container .store-card-btn-line{
        width: 80%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
    }

    .store-card-info-mobile-container{
        width: 49%;
        height: 210px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

}


@media screen and (max-width: 479px) {
    .store-wrapper {
        width: calc(100% - 40px);
    }

    .store-title {
        font-size: 40px;
        line-height: 42px;
    }

    .store-filter-title{
        font-size: 13px;
        line-height: 16px;
    }

    .store-card {
        min-width: unset;
        width: 100%;
        height: 459px;
        margin-bottom: 20px;
    }

    .store-card-img {
        height: auto;
    }

    .store-card-title{
        font-size: 16px;
        line-height: 20px;
    }

    .store-card-author{
        font-size: 14px;
        line-height: 18px;
    }
    .store-card-subtitle{
        margin-bottom: 5px;
    }

    .store-card-price{
        font-size: 15px;
        line-height: 19px;
    }
}