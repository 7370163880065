

@media screen and (max-width: 768px) {
    .button {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 100px;
        transform: translateX(100%);
        transition: all 0.2s;
        z-index: 104;
    }
    .button.active{
        transform: translateX(0);
    }

    .menu {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 100px;
        transform: translateX(100%);
        transition: all 0.2s;
        z-index: 104;
    }
    
    .menu.active {
        transform: translateX(0);
    }
    
    .menu__content {
        height: 100%;
        background-color: #353535;
        display: flex;
        flex-direction: column;
        transition: all 0.4s;
    }
    
    .menu__header {
        padding: 10px;
        font-size: 2rem;
        color: white;
        border: 1px solid white;
        width: 100%;
        text-align: center;
    }

    .links-menu{
        font-family: 'Arsenal';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        /* identical to box height */

        letter-spacing: 0.03em;

        color: #FFFFFF;

        height: 65px;
        box-sizing: border-box;
        padding: 20px 0px 20px 20px;
        text-decoration: none;
        border-top: 1px solid white;
    }
    .links-menu.events{
        border-bottom: 1px solid white;
    }

    .menu__content {
        width: 100%;
    }
    .menu__button{
            width: 60px;
            height: 64px;
            background-color: #353535;
            padding: 0px;
            border: 0px;
            gap: 20px;      
    }
    .menu__button:hover{
        cursor: pointer;
    }
    .menu__wrap{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border-bottom: 1px solid white;
        border-top: 1px solid white;
    }
    
}