.tooltip {
  position: relative;
  display: inline-block;
  
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  
  background: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  width: 382px;
  bottom: 100%;
  left: 50%;
  margin-left: -185px;
  margin-bottom: 5px;
}

@media screen and (max-width: 650px){
  .tooltip:hover .tooltiptext{
    width: 140px;
    margin-left: -70px;
  }
  .tooltip .tooltiptext {
    font-size: 10px;
  }
}