@import url('https://fonts.googleapis.com/css2?family=Arsenal:wght@400;700&family=Poppins:wght@600&family=Roboto&display=swap');

.container-topweek {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.topweek-text {
    display: flex;
    width: 100%;
    max-height: 261px;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 30px;
}

.topweek-photo {
    display: flex;
    gap: 30px;
    max-width: 870px;
    width: 100%;
    order: 1;
    align-self: flex-end;
}

.topweek-photo img{
    min-width: 0;
    max-width: 100%;

    width: 270px;
    height: 260px; 

    object-fit: cover;
}

.showMoreBtn{
    width: 170px;
    height: 65px;
    background: #353535;
    padding: 0px;
    border: 0px;

    /* Text */
    color: white;
    font-family: 'Arsenal';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    cursor: pointer;

    transition: all 0.2s ease-in;
}

.showMoreBtn:hover{
    transform: scale(1.01);
    box-shadow: 0 6px 4px darkgray;
}

.showMoreBtn:active{
    transform: scale(0.99);
    box-shadow: 0 6px 4px darkgray;
}

.narrowTopText{
    margin: 0;

    font-family: 'Moniqa';
    font-style: normal;
    font-weight: 400;
    font-size: 70px;
    line-height: 83px;
    letter-spacing: 0.03em;
    /* текст */
    color: #353535;
    text-transform: uppercase;
}

@media (max-width:1189px) {
    .container-topweek{
        max-height: 224px;
        max-width: 960px;
    }

    .topweek-text{
        height: 224px;
        row-gap: unset;
        column-gap: unset;
    }
    
    .narrowTopText{
        font-size: 60px;
        line-height: 70px;
    }

    .topweek-photo{
        max-width: 715px;
        gap: 20px;
    }

    .showMoreBtn{
        height: 58px;
    }
}

@media (max-width:999px) {
    .container-topweek{
        max-width: 728px;
    }
    .topweek-photo img:nth-child(3){
        display: none;
    }

    .topweek-photo{
        max-width: 479px;
    }
}

@media (max-width:767px) {
    .container-topweek{
        max-width: 100%;
        max-height: unset;
        position: relative;
    }

    .topweek-text{
        height: unset;
        max-height: unset;
        max-width: 100%;
        width: 100%;
        gap: 20px;
    }

    .topweek-photo{
        max-width: unset;
        order: unset;
        align-self: center;
        width: unset;
    }

    .topweek-photo img{
        width: 50%;
        height: unset;
    }

    .narrowTopText{
        font-size: 46px;
        line-height: 50px;
    }

    .showMoreBtn{
        height: 45px;
        width: 155px;
        font-size: 13px;
        align-self: center;
    }
}

@media (max-width:479px) {
    .narrowTopText{
        font-size: 36px;
        line-height: 40px;
    }

    .topweek-text{
        max-width: 100%;
        width: 100%;
    }

    .showMoreBtn{
        height: 45px;
        width: 145px;
        font-size: 12px;
        align-self: center;
    }
}