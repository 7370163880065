.wrapper-store-card-page {
    width: 100%;
    min-height: 100vh;
    padding-bottom: 100px;
}

.container-store-card-page {
    max-width: 1170px;
    margin: 0 auto;
}

.title-container-store-card-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 133px;
    border-top: 1px solid #B3B3B3;
    border-bottom: 1px solid #B3B3B3;
    gap: 10px;
}

.title-store-card-page {
    font-family: 'Arsenal';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.03em;
    color: #353535;

    text-align: center;
}

.title-sub-store-card-page {
    font-family: 'Arsenal';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.03em;
    color: #B3B3B3;
}

.buy-container-store-card-page {
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 20px;
    padding-top: 30px;
    padding-bottom: 10px;
}

.buy-image-container {
    width: 570px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}


.buy-image-container img {
    min-width: 0;
    object-fit: cover;
    width: 100%;
}


.buy-image-sub {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
    width: 500px;
    height: 120px;
}

.buy-image-sub img {
    width: 120px;
    height: 120px;
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid #353535;
    cursor: pointer;
    transition: all 0.2s ease-in;
}

.buy-text-container {
    display: flex;
    flex-direction: column;
    width: 570px;
}

.buy-text-title {
    font-family: 'Arsenal';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.03em;
    color: #353535;
}

.buy-text-content {
    font-family: 'Arsenal';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.03em;
    color: #353535;
}

.buy-button-price-container {
    width: 417px;
    height: 139px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.buy-button-container {
    display: flex;
    justify-content: space-between;
    height: 80px;
}

.buy-button-price-line {
    font-family: 'Arsenal';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.03em;
    color: #353535;
}

#button-buy {
    width: 293px;
    height: 100%;
    background: #353535;
    border: 0;
    cursor: pointer;

    font-family: 'Arsenal';
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    transition: all 0.2s ease-in;
}

#button-buy:hover {
    transform: scale(1.01);
    box-shadow: 0 6px 4px darkgray;
}

#button-buy:active {
    transform: scale(0.99);
    box-shadow: 0 6px 4px darkgray;
}

#button-like {
    width: 114px;
    height: 100%;
    background: #353535;
    border: 0;
    cursor: pointer;
    transition: all 0.2s ease-in;
}

#button-like:hover {
    transform: scale(1.01);
    box-shadow: 0 6px 4px darkgray;
}

#button-like:active {
    transform: scale(0.99);
    box-shadow: 0 6px 4px darkgray;
}

.graph-container-store-card-page {
    height: 495px;
    border-top: 1px solid #B3B3B3;
    border-bottom: 1px solid #B3B3B3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.graph-img-table-container {
    display: flex;
    justify-content: center;
    gap: 30px;
    max-width: 100%;
}

.graph-table {
    display: flex;
    flex-direction: column;
    height: 325px;
    justify-content: space-between;
}

.graph-table-w {
    width: 249px;
    height: 95px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.greaph-light-text {
    font-family: 'Arsenal';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.03em;
    color: #B3B3B3;
}

.graph-dark-text {
    font-family: 'Arsenal';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
    color: #353535;
}

.graph-table-box {
    width: 100%;
    height: 65px;
    border: 1px solid #353535;

    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 7px;
    justify-content: space-between;
    cursor: pointer;
}

#Graph {
    max-width: fit-content;
}

.about-container-store-card-page {
    box-sizing: border-box;
    margin: 30px 0;
    height: 509px;
    display: flex;
    justify-content: space-between;
}

.about-img-container {
    width: 370px;
    height: 508px;
    position: relative;
    display: flex;
    justify-content: center;
}

.about-btn {
    position: absolute;
    bottom: 30px;
    border: 0;
    width: 210px;
    height: 57px;

    font-family: 'Arsenal';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #353535;
}

#aboutImg {
    min-width: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

#aplusImg {
    position: absolute;
    object-fit: contain;
    right: 10px;
    top: 10px;
}

.about-text-container {
    width: 771px;
    display: flex;
    flex-direction: column;
}

.buy-text-content.about {
    width: 100%;
}

.achievements-container-store-card-page {
    height: 389px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.achievements-title {
    font-family: 'Arsenal';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.03em;
    color: #353535;
}

.achievements-img-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.achievements-img-box {
    width: 270px;
    height: 339px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.achievements-img-box img {
    min-width: 0;
    object-fit: cover;
    width: 100%;
}

.achievements-box-title {
    font-family: 'Arsenal';
    font-style: italic;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #353535;
}

.achievements-box-content {
    font-family: 'Arsenal';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #B3B3B3;
}



@media screen and (max-width:1189px) {
    .container-store-card-page {
        max-width: 960px;
    }

    .buy-image-container {
        width: 471px;
    }

    .buy-image-sub {
        width: 379px;
        height: 91px;
    }

    .buy-image-sub img {
        width: 91px;
        height: 91px;
        padding: 8px;
    }

    .buy-text-container {
        width: 470px;
        height: 470px;
    }

    .buy-container-store-card-page {
        column-gap: 19px;
    }

    .buy-text-content {
        overflow: hidden;
    }

    .buy-text-title {
        font-size: 20px;
    }

    .buy-button-price-container {
        height: 134px;
    }

    .buy-button-price-line {
        font-size: 28px;
    }

    .graph-container-store-card-page {
        height: 476px;
        height: 500px;
    }

    .about-text-container {
        width: 552px;
    }

    .about-img-container {
        height: unset;
        width: 388px;
    }

    .achievements-container-store-card-page {
        height: 425px;
    }

    .achievements-img-container div:nth-child(4) {
        display: none;
    }

    .achievements-img-box {
        width: 307px;
        height: 375px;
    }

}

@media screen and (max-width:999px) {

    .wrapper-store-card-page {
        padding-bottom: 80px;
    }

    .container-store-card-page {
        max-width: 728px;
    }

    .buy-image-container {
        width: 354px;
    }

    .buy-image-sub {
        width: 288px;
        height: 71px;
    }

    .buy-image-sub img {
        width: 71px;
        height: 71px;
        padding: 8px;
    }

    .buy-text-container {
        width: 354px;
        height: 360px;
    }

    .buy-button-price-line {
        font-size: 24px;
    }

    .graph-img-table-container {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .graph-container-store-card-page {
        height: 587px;
    }

    .graph-table {
        flex-direction: row;
        height: unset;
        width: 100%;
    }

    .graph-table-w {
        width: 229px;
        height: 91px;
    }

    .graph-table-box {
        height: 58px;
        padding-right: 10px;
    }

    .greaph-light-text {
        font-size: 15px;
    }

    .graph-dark-text {
        font-size: 14px;
    }

    .about-container-store-card-page {
        height: 374px;
    }

    .about-img-container {
        width: 292px;
    }

    .about-text-container {
        width: 416px;
    }

    .about-btn {
        height: 53px;
    }

    .achievements-container-store-card-page {
        height: 342px;
    }

    .achievements-img-box {
        height: 297px;
        width: 229px;
    }
}

@media screen and (max-width:767px) {
    .container-store-card-page {
        width: calc(100% - 40px);
    }

    .buy-container-store-card-page {
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
    }

    .buy-image-container {
        width: calc(100% - 50px);
    }

    .buy-text-container {
        width: unset;
    }

    .buy-text-content {
        overflow: auto;
    }

    .buy-button-price-container {
        width: 100%;
        align-items: center;
    }

    .buy-button-container {
        gap: 12px;
        justify-content: unset;
        width: 100%;
    }

    #button-buy {
        width: unset;
        flex: 2.5 0;
    }

    #button-like {
        width: unset;
        flex: 1 0;
    }

    #Graph {
        min-width: 0;
        object-fit: cover;
        max-width: 100%;
    }

    .graph-container-store-card-page {
        height: unset;
        gap: 20px;
        padding: 30px 0;
    }

    .graph-table {
        flex-wrap: wrap;
        gap: 20px;
    }

    .graph-table-w {
        flex: 1 0 229px;
    }


    .about-container-store-card-page {
        flex-direction: column;
        align-items: center;
    }

    .about-img-container {
        width: calc(100% - 140px);
    }

    #aboutImg {
        height: 374px;
    }

    .about-container-store-card-page {
        height: 784px;
        gap: 20px;
    }

    .about-text-container {
        overflow: hidden;
        width: unset;
        align-items: center;
    }

    .achievements-container-store-card-page {
        align-items: center;
        height: unset;
        gap: 30px;
    }

    .achievements-img-container {
        flex-wrap: wrap;
        gap: 20px;
        max-width: 665px;
    }

    .achievements-img-box {
        flex: 1 0 210px;
        height: unset;
        gap: 5px;
    }

    .achievements-img-container div:nth-child(4) {
        display: unset;
    }

    .achievements-box-title {
        font-size: 15px;
    }

    .achievements-box-content {
        font-size: 13px;
    }
}

@media screen and (max-width:479px) {

    .wrapper-store-card-page {
        padding-bottom: 60px;
    }

    .title-container-store-card-page {
        height: 126px;
    }

    .buy-image-container {
        gap: 15px;
    }

    .buy-image-sub {
        width: 228px;
        height: unset;
    }

    .buy-image-sub img {
        width: 56px;
        height: 56px;
    }

    .buy-button-price-container {
        height: 100px;
    }

    .buy-button-container {
        height: 55px;
    }

    .about-btn {
        width: 191px;
        height: 50px;
    }

    #aplusImg {
        width: 50px;
    }

    .about-img-container {
        width: calc(100% - 40px);
    }

    .achievements-img-container {
        width: calc(100% - 70px);
    }
}

.investment {
    font-family: 'Arsenal', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    padding: 30px 0;
    border-top: 1px solid #B3B3B3;
    border-bottom: 1px solid #B3B3B3;
    margin: 20px 0;
}

.investment-title-text {
    font-size: 24px;
    font-weight: 700;
    line-height: 30.1px;
}

.investment-price {
    display: flex;
    gap: 100px;
}

.investment-price-max {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.investment-price-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 20.06px;
    color: #B3B3B3;
}

.investment-price-button {
    border: 1px solid #B3B3B3;
    font-size: 15px;
    font-weight: 700;
    line-height: 18.81px;
    min-width: 229px;
    padding: 24px 0;
    padding-left: 20px;
}

.subtitle-link {
    text-decoration: none;
    color: #FFFFFF;
    font-size: 15px;
    font-weight: 700;
}

.subtitle-link-register {
    text-decoration: none;
    color: #353535;
    font-size: 15px;
    font-weight: 700;
}

.modal-window {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    max-width: 380px;
}

.modal-window-arrow {
    width: 50px;
    height: 50px;
    background-color: #353535;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    margin-bottom: -30px;
}

.modal-window-info {
    font-family: 'Arsenal', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 35px 20px; */
    z-index: 10;
    flex-direction: column;
    gap: 15px;
    padding: 35px 30px;
    color: #FFFFFF;
    background-color: #353535;

}

.modal-window-info-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    text-align: center;
}

.modal-window-info-subtitle {
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    line-height: 30px;
    max-width: 320px;
}

.modal-window-button {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.modal-login {
    background: transparent;
    border: 1px solid #FFFFFF;
    padding: 16px 54px;
}

.modal-register {
    background-color: #ffffff;
    padding: 16px 32px;
    border: none;
}

@media screen and (max-width: 640px) {
    .investment {
        width: calc(100% - 40px);
        margin: auto;
        margin-top: 20px;
    }

    .investment-price {
        flex-direction: column;
        gap: 40px;
    }

}

@media screen and (max-width: 440px) {
    .modal-window-info {
        width: calc(100% - 70px);
        margin: auto;
    }

    .investment-title-text {
        font-size: 18px;
    }

    .modal-window-button {
        flex-direction: column;
        width: 100%;
    }

    .modal-login {
        width: 100%;
    }

    .modal-register {
        width: 100%;
    }
}