
.wrapper-collectioners{
    min-height: 100vh;
}

.container-collectioners{
    max-width: 1170px;
    margin: 0 auto;
}

.container-collectionersCard{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 50px 0 100px 0;
    border-top: 1px solid #B3B3B3;
    gap: 30px;
}

.text-collectionersCard{
    font-family: 'Arsenal';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #353535;

    max-width: 770px;
    align-self: center;
}

.collectioners-img-btn{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.collectioners-img-btn img{
    min-width: 0;
    object-fit: cover;
    max-width: 370px;
    height: 508px;
}

.collectioners-btn{
    position: absolute;
    width: 210px;
    height: 57px;
    background: white;
    bottom: 30px;
    padding: 0px;
    border: 0px;
    
    font-family: 'Arsenal';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #353535;
}

.full-collection{
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    max-height: 366px;
    flex-wrap: wrap;
    padding-top: 50px;
    border-top: 1px solid #B3B3B3;
}

.full-collection-photo{
    display: flex;
    max-width: 870px;
    max-height: 315px;
    order: 1;
    column-gap: 30px;
    align-self: flex-end;
}

.full-collection-photo-sub{
    max-width: 270px;
}

.full-collection-photo-sub img{
    min-width: 0;
    width: 100%;
    object-fit: cover;
}

.full-collection-photo-sub p{
    margin: 10px 0 0 0;

    font-family: 'Arsenal';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.03em;
    color: #353535;
    text-align: center;
}

@media (max-width:1189px) {
    .container-collectioners{
        max-width: 960px;
    }

    .container-collectionersCard {
        padding: 50px 0 70px 0;
    }

    .full-collection{
        row-gap: 20px;
        height: 260px;
        padding-top: 30px;
    }

    .full-collection-photo{
        max-width: 715px;
        max-height: 250px;
        column-gap: 20px;
    }

    .full-collection-photo-sub {
        width: 225px;
    }
    .narrowTopText.collection{
        font-size: 54px;
    }

    .full-collection-photo-sub img {
        max-height: 200px;
    }
    .full-collection-photo-sub p {
        margin: 5px 0 0 0;
    }
}

@media (max-width:999px) {
    .container-collectioners{
        max-width: 728px;
    }

    .full-collection-photo{
        max-width: 728px;
    }

    .full-collection-photo div:nth-child(3){
        display: none;
    }

    .full-collection-photo-sub p{
        font-size: 22px;
    }
}

@media (max-width:767px) {

    .container-collectioners{
        max-width: 95%;
    }

    .full-collection{
        flex-wrap: nowrap;
        height: unset;
        max-height: unset;
    }

    .full-collection-photo{
        max-width: 100%;
        max-height: unset;
        order: unset;
        align-self: center;
    }

    .full-collection-photo-sub {
        width: unset;
    }

    .full-collection-photo-sub img {
        max-height: unset;
    }

    .narrowTopText.collection{
        font-size: 46px;
    }

    .collectioners-img-btn img{
        max-width: 92%;
        max-height: 390px;
    }
}

@media (max-width:479px) {
    .narrowTopText.collection{
        font-size: 36px;
    }

    .full-collection-photo-sub p{
        font-size: 19px;
        margin: 3px 0 0 0;
    }

    .container-collectionersCard {
        padding: 30px 0 60px 0;
    }

    .collectioners-btn{
        width: 191px;
        height: 50px;
    }
    
}
