@import url('https://fonts.googleapis.com/css2?family=Arsenal&family=Poppins:wght@600&family=Roboto&display=swap');

.wrapper-footer {
    background: #F7F7F7;
    height: 251px;
    display: flex;
    justify-content: center;
}

.container-footer {
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0px 20px;
}

.footer-text {
    font-family: 'Arsenal';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 0.03em;
    color: #949494;
    margin-top: 30px;
    margin-bottom: 0px;
}

#footerLogo {
    width: 269px;
    height: 102px;
}

@media (max-width:999px) {
    #footerLogo {
        width: 229px;
        height: 81px;
    }

    .wrapper-footer {
        height: 210px;
    }

    .footer-text{
        margin-top: 15px;
    }
}

@media (max-width:767px) {
    #footerLogo {
        width: 210px;
        height: 75px;
    }

    .wrapper-footer {
        height: 200px;
    }
}

@media (max-width:479px) {
    #footerLogo {
        width: 175px;
        height: 62px;
    }

    .wrapper-footer {
        height: 180px;
    }
}
